<template>
  <div class="article" v-if="canView">
    <page-header
      :title="article.name"
      icon="fa fa-dice"
      :links="getLinks()"
    >
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row v-if="article" v-show="!isLoading(loadingName) && !editMode">
      <b-col cols="3" class="detail-col">
        <article-info :article="article"></article-info>
      </b-col>
      <b-col cols="3" class="detail-col">
        <article-items-info :article="article" @reload="loadBorrowingList()" :can-borrow="false">
        </article-items-info>
      </b-col>
      <b-col cols="6" ref="excelMe" v-if="hasPerm('store.view_borrowing')">
        <div ref="printMe">
          <div class="article-title">
            <b-row>
              <b-col>
                <h2 style="display: inline-block">
                  <span class="hide-here">{{ article.name}} - </span>
                  Historique des emprunts
                </h2>
              </b-col>
            </b-row>
          </div>
          <x-table
            :columns="columns"
            :items="borrowingItems"
            :show-counter="true"
            verbose-name="emprunt"
            responsive
          ></x-table>
        </div>
      </b-col>
    </b-row>
    <article-editor
      :article="article"
      v-if="editMode"
      @done="onArticleUpdated($event)"
      @cancel="onEditCancelled($event)"
    ></article-editor>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import ArticleInfo from '@/components/Store/ArticleInfo'
import ArticleEditor from '@/components/Store/ArticleEditor'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import { makeBorrowing, makeStoreArticle } from '@/types/store'
import store from '@/store'
import ArticleItemsInfo from '@/components/Store/ArticleItemsInfo.vue'
import { dateToString } from '@/filters/texts'

export default {
  name: 'ArticleDetail',
  props: {
    articleId: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    ArticleItemsInfo,
    ArticleInfo,
    ArticleEditor,
    XTable,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      article: null,
      loadingName: 'edit-article',
      borrowings: [],
      columns: [],
      editMode: false,
    }
  },
  async created() {
    await this.loadData()
    await this.loadBorrowingList()
    this.columns = this.getBorrowingColumns()
  },
  watch: {
    articleId: async function() {
      await this.loadData()
      await this.loadBorrowingList()
    },
    article: function(newValue, oldValue) {},
    title: function() {
      store.dispatch('changeNav', { title: this.title, icon: store.getters.navIcon, })
    },
  },
  computed: {
    title() {
      return this.article ? this.article.name : ''
    },
    canView() {
      return this.article && this.hasPerm('store.view_article')
    },
    canChange() {
      return this.hasPerm('store.change_article')
    },
    borrowingItems() {
      return this.borrowings.map(
        elt => this.makeBorrowingItem(elt)
      )
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getBorrowingColumns() {
      const columns = [
        {
          name: 'name',
          label: 'Article',
          onClick: item => {
            this.viewArticle(item.article)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'reference',
          label: 'Référence',
        },
        {
          name: 'entity',
          label: 'Famille',
        },
        {
          name: 'borrowedOn',
          label: 'Emprunté le',
        },
        {
          name: 'expectedOn',
          label: 'Attendu le',
        },
        {
          name: 'returnedOn',
          label: 'Rendu le',
        },
        {
          name: 'status',
          label: 'Stade',
        }
      ]
      return columns
    },
    async loadData() {
      this.startLoading(this.loadingName)
      try {
        let url = '/store/api/articles/' + this.articleId + '/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.article = makeStoreArticle(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadBorrowingList() {
      this.startLoading(this.loadingName)
      let url = '/store/api/article-borrowings/' + this.articleId + '/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.borrowings = resp.data.map(elt => makeBorrowing(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'emprunts-' + slugify(this.article.name)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const links = []
      if (!this.editMode) {
        if (this.canView) {
          links.push(
            {
              id: 5,
              label: 'Pdf',
              callback: this.printMe,
              icon: 'fa fa-file-pdf',
              cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
            }
          )
          links.push(
            {
              id: 4,
              label: 'Excel',
              callback: this.excelMe,
              icon: 'fa fa-file-excel',
              cssClass: 'btn-secondary',
            }
          )
        }
        if (this.canChange) {
          links.push(
            {
              id: 1,
              label: 'Modifier',
              callback: this.editArticle,
              icon: 'fa fa-edit',
              cssClass: 'btn-secondary',
            }
          )
        }
      }
      return links
    },
    makeBorrowingItem(elt) {
      return {
        name: elt.article.name,
        reference: elt.item.reference,
        entity: elt.entity.name,
        article: elt.article,
        borrowedOn: dateToString(elt.borrowedOn),
        expectedOn: dateToString(elt.returnExpectedOn),
        returnedOn: elt.returnedOn ? dateToString(elt.returnedOn) : '',
        status: elt.getStatus(),
      }
    },
    editArticle() {
      this.editMode = true
    },
    onArticleUpdated(event) {
      this.article = event.article
      this.editMode = false
    },
    onEditCancelled() {
      this.loadData()
      this.editMode = false
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const articleSlug = slugify(this.article.name)
      const docSlug = 'emprunts-' + articleSlug
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToArticle(article) {
      return { name: 'store-article-detail', params: { articleId: '' + article.id, }, }
    },
  },
}
</script>

<style scoped lang="less">
  .article-title {
    padding: 5px;
    background: #e0e0e0;
  }
</style>
